import React, { useEffect, useState } from "react";
import MainLayout from "../../layouts/MainLayout";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  CssBaseline,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  SvgIcon,
  TextField,
  Typography,
} from "@mui/material";
import { theme } from "../../constant/Theme";
import { Clear as ClearIcon } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { inputExhibitStyles } from "../Exhibits/style";
import {
  NoteInput,
  ViewFileNoteInput,
} from "../../constant/ConstantInputField";
import { useSelector } from "react-redux";
import { useAxios } from "../../services/http.service";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { a } from "@table-library/react-table-library/styles-492c6342";

function ViewFilePage() {
  const prevRoute = useLocation();
  const [fileViewContent, setFileViewContent] = useState();
  const [fileType, setFileType] = useState("");
  const [value, setValue] = useState(prevRoute?.state?.data?.note || "");
  const { token } = useSelector((state) => state?.reducer?.user?.userData);
  const [issues, setIssues] = useState([]);
  const [selectedIssue, setSelectedIssue] = useState(
    issues.length > 0 && prevRoute?.state?.data?.issues > 0
      ? issues.filter((issue) =>
          prevRoute?.state?.data?.issues.includes(issue._id)
        )
      : []
  );

  const { get, setBearerToken, put } = useAxios();
  const navigate = useNavigate();
  const [isFadingOut, setIsFadingOut] = useState(false);
  console.log(prevRoute?.state?.data);

  useEffect(() => {
    const selectedIds = prevRoute?.state?.data?.issues || [];
    if (issues.length > 0 && selectedIds.length > 0) {
      const selectedIssues = issues.filter((issue) =>
        selectedIds.includes(issue._id)
      );
      setSelectedIssue(selectedIssues);
    }
  }, [issues, prevRoute?.state?.data?.issues]);
  useEffect(() => {
    const url = prevRoute?.state?.viewerUrl;
    if (url) {
      downloadAndCheckFile(url);
    }
  }, [prevRoute?.state?.viewerUrl]);

  const downloadAndCheckFile = async (url) => {
    try {
      const response = await axios.get(url, {
        responseType: "arraybuffer",
      });

      const blob = new Blob([response.data]);
      const type = response.headers["content-type"] || blob.type;

      // Check file type
      if (type.startsWith("image/")) {
        setFileType("image");
        setFileViewContent(URL.createObjectURL(blob));
      } else {
        setFileViewContent(prevRoute?.state?.viewerUrl);
      }
    } catch (error) {
      console.error("Error downloading file:", error);
      setFileType("error");
    }
  };
  const mutation = useMutation(
    async () => {
      const formData = { issues: selectedIssue, note: value };
      setBearerToken(token);
      const path = prevRoute?.state?.path || "";
      const basePath = "/" + path.split("/")[1];
      return put(`${basePath}/${prevRoute?.state?.data?._id}`, formData);
    },
    {
      onSuccess: async (response) => {
        if (response.status === 200) {
          toast.success("Record has been updated successfully.", {
            position: "bottom-right",
          });
        }
      },
      onError: (error) => {
        if (error) {
          toast.error("Something Went Wrong", {
            position: "bottom-right",
          });
        }
      },
    }
  );

  const handleClose = () => {
    setIsFadingOut(true);
    setTimeout(() => {
      navigate(-1);
    }, 200);
  };
  const getAllCases = async () => {
    try {
      setBearerToken(token);
      const response = await get("/Jurisprudence");

      setIssues(
        response?.data?.data.sort((a, b) => a?.name.localeCompare(b?.name))
      );
    } catch (error) {}
  };
  useEffect(() => {
    getAllCases();
  }, []);
  const MenuProps = {
    PaperProps: {
      style: {
        width: 250,
        maxHeight: 48 * 4.5 + 8,
      },
    },
  };
  return (
    <MainLayout>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            backgroundColor: theme?.palette?.background?.paper,
            color: "white",
          }}
        >
          <Box
            sx={{
              minHeight: "100vh",
              maxHeight: { xs: 2000, sm: 2000 },
              position: "relative",
              transition: "opacity 0.3s ease",
              opacity: isFadingOut ? 0 : 1,
            }}
          >
            <CssBaseline />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: 2,
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      color: theme?.palette?.primary?.main,
                      fontFamily: theme?.typography?.fontFamily,
                    }}
                  >
                    View File
                  </Typography>
                  <IconButton sx={{ color: "#2B2C40" }} onClick={handleClose}>
                    <ClearIcon />
                  </IconButton>
                </Box>
                <Divider
                  sx={{
                    backgroundColor: theme?.palette?.divider,
                    marginBottom: 2,
                  }}
                />

                {fileType === "image" ? (
                  <img
                    src={fileViewContent}
                    alt="File Preview"
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  <>
                    <iframe
                      src={fileViewContent}
                      title="File Preview"
                      style={{ width: "100%", height: "100vh", border: "none" }}
                    />
                    <Grid
                      xs={12}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginTop: "20px",
                      }}
                    >
                      <Grid
                        xs={12}
                        sx={{
                          color: "black",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "start",
                          width: "100%",
                        }}
                      >
                        <Autocomplete
                          id="issue"
                          multiple
                          options={issues}
                          getOptionLabel={(option) => {
                            return option.name;
                          }}
                          autoComplete
                          size="small"
                          includeInputInList
                          value={selectedIssue}
                          onChange={(event, newValue) => {
                            // Update the selectedIssue state with the new array of selected issues
                            setSelectedIssue(newValue);
                          }}
                          sx={{ width: "40%" }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Jurisprudence"
                              variant="outlined"
                              fullWidth
                              InputLabelProps={{
                                sx: {
                                  color: theme?.palette?.primary?.main,
                                  "&.Mui-focused": {
                                    color:
                                      theme?.palette?.primary?.placeholderColor,
                                  },
                                },
                              }}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  color:
                                    theme?.palette?.primary?.placeholderColor,
                                  "& fieldset": {
                                    borderColor:
                                      theme?.palette?.secondary?.inputColor,
                                  },
                                  "&:hover fieldset": {
                                    borderColor:
                                      theme?.palette?.secondary?.hoverColor,
                                  },
                                  "&.Mui-focused fieldset": {
                                    borderColor:
                                      theme?.palette?.primary?.inputColor,
                                  },
                                },
                                "& .MuiSvgIcon-root": {
                                  color:
                                    theme?.palette?.primary?.placeholderColor,
                                },
                              }}
                            />
                          )}
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                checked={selected}
                                sx={{
                                  color:
                                    theme?.palette?.primary?.placeholderColor,
                                  backgroundColor: "transparent",
                                  "&.Mui-checked": {
                                    color: "#686DFC",
                                    backgroundColor: "transparent",
                                  },
                                  "& .MuiSvgIcon-root": {
                                    fontSize: "1.2rem",
                                  },
                                }}
                              />
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  color:
                                    theme?.palette?.primary?.placeholderColor,
                                }}
                                paddingLeft={1}
                              >
                                {option.name}
                              </Typography>
                            </li>
                          )}
                          PaperComponent={(props) => (
                            <Paper
                              {...props}
                              sx={{
                                backgroundColor:
                                  theme?.palette?.primary?.subSecondary,
                                color:
                                  theme?.palette?.primary?.placeholderColor,
                              }}
                            />
                          )}
                          ListboxProps={{
                            sx: {
                              maxHeight: 200,
                              overflowY: "auto",
                              "& .MuiAutocomplete-option": {
                                display: "flex",
                                alignItems: "center",
                                "&:hover": {
                                  backgroundColor:
                                    theme?.palette?.primary?.tertiaryShades,
                                  color:
                                    theme?.palette?.primary?.placeholderColor,
                                },
                              },
                              "& .MuiAutocomplete-noOptions": {
                                color: "red",
                                textAlign: "center",
                              },
                            },
                          }}
                          renderTags={(value, getTagProps) => {
                            const maxVisibleChips = 3;
                            const extraCount = value.length - maxVisibleChips;
                            return (
                              <>
                                {value
                                  .slice(0, maxVisibleChips)
                                  .map((option, index) => (
                                    <Chip
                                      {...getTagProps({ index })}
                                      label={option?.name}
                                      deleteIcon={
                                        <SvgIcon
                                          sx={{
                                            color: "#686DFC",
                                            width: "15px",
                                            height: "15px",
                                          }}
                                        >
                                          <ClearIcon
                                            sx={{
                                              width: "15px",
                                              height: "15px",
                                              color: "#686DFC",
                                            }}
                                          />
                                        </SvgIcon>
                                      }
                                      sx={{
                                        backgroundColor:
                                          theme?.palette?.primary
                                            ?.tertiaryShades,
                                        color:
                                          theme?.palette?.primary
                                            ?.placeholderColor,
                                        height: "25px",
                                        textTransform: "capitalize",
                                        borderRadius: "2px",
                                        width: "100px",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        fontSize: "0.875rem",
                                      }}
                                    />
                                  ))}
                                {extraCount > 2 && (
                                  <Chip
                                    label={`${extraCount} more`}
                                    sx={{
                                      backgroundColor:
                                        theme?.palette?.primary?.tertiaryShades,
                                      color:
                                        theme?.palette?.primary
                                          ?.placeholderColor,
                                      textTransform: "capitalize",
                                      borderRadius: "2px",
                                      width: "45px",
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      fontSize: "0.875rem",
                                    }}
                                  />
                                )}
                              </>
                            );
                          }}
                          noOptionsText="No Jurisprudence found"
                        />
                      </Grid>
                      <Grid xs={12} sx={{ color: "black", width: "100%" }}>
                        <ViewFileNoteInput
                          value={value}
                          setValue={setValue}
                          label="Note"
                          placeholder="Enter your notes..."
                          rows={4}
                          theme={theme}
                        />
                      </Grid>
                      <Grid
                        xs={12}
                        sx={{
                          width: "100%",
                          color: "black",
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Button
                          onClick={() => mutation.mutate()}
                          disabled={mutation.isLoading}
                          variant="contained"
                          sx={{
                            width: { xs: "100%", sm: "150px" },
                            mt: { xs: 1, sm: 1.5 },
                            mb: { xs: 0, sm: 2 },
                            height: "45px",
                            "&:hover, &:focus": {
                              backgroundColor: "white",
                              color: "#FFFFFF",
                              backgroundColor:
                                theme?.palette?.secondary?.btnPrimary,
                              boxShadow: 0,
                            },
                            "&:disabled": {
                              backgroundColor:
                                theme?.palette?.secondary?.btnPrimary,
                              color: "white",
                              boxShadow: 0,
                            },
                            boxShadow: 0,
                            fontSize: "12px",
                            backgroundColor:
                              theme?.palette?.secondary?.btnPrimary,
                            fontWeight: "600",
                            fontFamily: theme?.typography?.fontFamily,
                          }}
                        >
                          Finish
                        </Button>
                      </Grid>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Grid>
    </MainLayout>
  );
}

export default ViewFilePage;
