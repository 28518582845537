import * as React from "react";

import { Badge, Grid, ListItemButton, ListItemIcon, ListItemText, MenuItem, Select, Stack } from "@mui/material";
import Pagination from "../Pagination/Pagination";
import SearchInput from "../Search/SearchInput";
import BasicTable from "./BasicTable";
import styled from "styled-components";

const CustomBadge = styled(Badge, { shouldForwardProp: (prop) => prop !== "hidden" })(({ theme, hidden }) => ({
  "& .MuiBadge-dot": {
    backgroundColor: "rgba(104, 109, 252, 0.7)", // Custom color for the badge dot
  },
  "& .MuiBadge-standard": {
    backgroundColor: "rgba(104, 109, 252, 0.7)", // Custom color for the badge content
  },
  display: hidden ? "none" : "inline-block", // Hide badge if 'hidden' prop is true
}));
const TableComponent = ({
  filterSearch,
  setFilterSearch,
  filter,
  handleOpen,
  currentPage,
  setCurrentPage,
  searchText,
  setSearchText,
  limit,
  setLimit,
  data,
  headingTable,
  valuesKeys,
  status,
  resetPassword,
  resetPasswordUrl,
  statusActiveUrl,
  viewPageUrl,
  deleteUrl,
  isDelete,
  editPageUrl,
  isLoading,
  isDownload,
  onClickDownload,
  viewMatterUrl,
}) => {
  // const selectedFiltersCount = Object.keys(filterSearch).length;

  return (
    <>
      <Grid
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
        style={{ padding: "0 14px" }}
      >
        <Grid
          item
          xs={2}
          sm={1.1}
          sx={{
            marginRight: { xs: 0.5, md: 2 },
            display: filter ? "flex" : "none",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
         
          {filterSearch &&
          Object.values(filterSearch).filter((value) => {
            // Check if value is a non-empty string or a number string
            return (typeof value === "string" && value.trim().length > 0) || (typeof value === "number" && value > 0);
          }).length > 0 ? (
            <CustomBadge
              badgeContent={
                Object.values(filterSearch).filter((value) => {
                  return (typeof value === "string" && value.trim().length > 0) || (typeof value === "number" && value > 0);
                }).length
              }
            > 
              <ListItemButton
                onClick={handleOpen}
                sx={{
                  minWidth: { xs: "auto", sm: "auto", md: "100px" },
                  borderRadius: "5px",
                  display: "flex",
                  justifyContent: "space-between",
                  color: "#2B2C40", 
                  borderColor: "#C8C9DB",
                  border: "1px solid",
                  padding: "4px 16px",
                   background: "#FFFFFF",
                  "&:hover": {
                    border: "1px solid",
                    borderColor: "#C8C9DB",
                    background: "#FFFFFF", 
                  },
                  paddingX: { xs: 1.2, sm: 2 },
                }}
              >
                <ListItemText primary={"Filters"} sx={{ display: { xs: "none", md: "block" } }} />

                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    margin: 0,
                    paddingY: { xs: 0.5, sm: 0 },
                    paddingX: 0,
                  }}
                >
                  <svg width="15" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M7.50083 7V12.91C7.53083 13.135 7.45583 13.375 7.28333 13.5325C7.21395 13.602 7.13153 13.6572 7.0408 13.6948C6.95007 13.7325 6.85281 13.7518 6.75458 13.7518C6.65635 13.7518 6.55909 13.7325 6.46836 13.6948C6.37763 13.6572 6.29522 13.602 6.22583 13.5325L4.71833 12.025C4.63658 11.945 4.57441 11.8472 4.53669 11.7392C4.49896 11.6312 4.48669 11.516 4.50083 11.4025V7H4.47833L0.158331 1.465C0.0365369 1.30865 -0.0184189 1.11044 0.00547166 0.913697C0.0293623 0.716951 0.130157 0.53766 0.285831 0.415C0.428331 0.31 0.585831 0.25 0.750831 0.25H11.2508C11.4158 0.25 11.5733 0.31 11.7158 0.415C11.8715 0.53766 11.9723 0.716951 11.9962 0.913697C12.0201 1.11044 11.9651 1.30865 11.8433 1.465L7.52333 7H7.50083Z"
                      // fill="#686DFC"
                      fill="#2B2C40"
                    />
                  </svg>
                </ListItemIcon>
              </ListItemButton>
            </CustomBadge>
          ) : (
            <ListItemButton
              onClick={handleOpen}
              sx={{
                minWidth: { xs: "auto", sm: "auto", md: "100px" },
                borderRadius: "5px",
                display: "flex",
                justifyContent: "space-between",
                color: "#2B2C40",
                // color: filterSearch && Object.keys(filterSearch).length > 0 ? "#686DFC" : "#2B2C40",
                borderColor: "#C8C9DB",
                border: "1px solid",
                padding: "4px 16px",
                "&:hover": {
                  border: "1px solid",
                  borderColor: "#C8C9DB",
                  background: "#FFFFFF", 
                },
                paddingX: { xs: 1.2, sm: 2 },
              }}
            >
              <ListItemText primary={"Filters"} sx={{ display: { xs: "none", md: "block" } }} />

              <ListItemIcon
                sx={{
                  minWidth: 0,
                  margin: 0,
                  paddingY: { xs: 0.5, sm: 0 },
                  paddingX: 0,
                }}
              >
                <svg width="15" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M7.50083 7V12.91C7.53083 13.135 7.45583 13.375 7.28333 13.5325C7.21395 13.602 7.13153 13.6572 7.0408 13.6948C6.95007 13.7325 6.85281 13.7518 6.75458 13.7518C6.65635 13.7518 6.55909 13.7325 6.46836 13.6948C6.37763 13.6572 6.29522 13.602 6.22583 13.5325L4.71833 12.025C4.63658 11.945 4.57441 11.8472 4.53669 11.7392C4.49896 11.6312 4.48669 11.516 4.50083 11.4025V7H4.47833L0.158331 1.465C0.0365369 1.30865 -0.0184189 1.11044 0.00547166 0.913697C0.0293623 0.716951 0.130157 0.53766 0.285831 0.415C0.428331 0.31 0.585831 0.25 0.750831 0.25H11.2508C11.4158 0.25 11.5733 0.31 11.7158 0.415C11.8715 0.53766 11.9723 0.716951 11.9962 0.913697C12.0201 1.11044 11.9651 1.30865 11.8433 1.465L7.52333 7H7.50083Z"
                    // fill="#686DFC"
                    fill={"#2B2C40"}
                  />
                </svg>
              </ListItemIcon>
            </ListItemButton>
          )}
        </Grid>

        {filterSearch && Object.keys(filterSearch).length > 0 && (
          <Grid item xs={1} sm={1} sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }} onClick={() => setFilterSearch({})}>
           </Grid>
        )}

        <Grid item xs={12} sm={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <SearchInput searchText={searchText} setSearchText={setSearchText} />
        </Grid>
      </Grid>

      {data?.data && (
        <BasicTable
          data={data?.data}
          isLoading={isLoading}
          headingTable={headingTable}
          valuesKeys={valuesKeys}
          status={status}
          resetPassword={resetPassword}
          resetPasswordUrl={resetPasswordUrl}
          statusActiveUrl={statusActiveUrl}
          viewPageUrl={viewPageUrl}
          deleteUrl={deleteUrl}
          isDelete={isDelete}
          editPageUrl={editPageUrl}
          isDownload={isDownload}
          viewMatterUrl={viewMatterUrl}
          onClickDownload={onClickDownload}
        />
      )}

      <br />

      <Stack spacing={10} sx={{ marginTop: "-35px" , marginBottom:'16px' ,display: "flex" }}>
        <Pagination setCurrentPage={setCurrentPage} currentPage={currentPage} limit={limit} setLimit={setLimit} totalPage={data?.totalPages} />
      </Stack>
    </>
  );
};

export default TableComponent;
