import { Button } from "@mui/material";
import { theme } from "../../constant/Theme";
import AddIcon from "@mui/icons-material/Add";
import LoaderIcon from "../Icons/LoaderIcon";
import { useNavigate } from "react-router-dom";

const ButtonUISubmit = ({
  text,
  handleClick,
  disable = false,
  type,
  inputStyle,
}) => {
  return (
    <Button
      variant="contined"
      type={type}
      disabled={disable}
      sx={{
        width: { xs: "100%", sm: "124px" },
        height: "40px",
        mt: { xs: 0, sm: 1.5 },
        mb: { xs: 0, sm: 2 },
        "&:hover, &:focus": {
          backgroundColor: theme.palette.secondary.btnPrimary,
          boxShadow: 0,
        },
        // Default styles applied here
        fontSize: "12px",
        backgroundColor: theme.palette.secondary.btnPrimary,
        fontWeight: "600",
        color: theme.palette.primary.bodyColor,
        "&:disabled": {
          backgroundColor: theme.palette.secondary.cancelBtn,
          color: theme.palette.primary?.bodyColor,
          fontFamily: theme.typography.fontFamily,
        },
        // Spread any additional styles from the inputStyle prop
        ...inputStyle,
      }}
      onClick={handleClick}
    >
      {text}
    </Button>
  );
};

export default ButtonUISubmit;

export const AddBtnUI = ({ title, onClick }) => {
  return (
    <Button
      onClick={onClick}
      variant="contained"
      sx={{
        width: { xs: "100%", sm: "auto" },
        mt: { xs: 0, sm: 1.5 },
        mb: { xs: 0, sm: 2 },
        // boxShadow: theme?.shadows[0],
        "&:hover, &:focus": {
          // boxShadow: theme?.shadows[1],
          backgroundColor: theme?.palette?.secondary?.btnPrimary,
          boxShadow: 0,
        },
        boxShadow: 0,
        fontSize: "14px",
        backgroundColor: theme?.palette?.secondary?.btnPrimary,
        fontWeight: 700,
        fontFamily: theme?.typography?.fontFamily,
        borderRadius: "4px",
        padding: "12px 24px",
      }}
    >
      <AddIcon
        fontSize="small"
        sx={{ marginRight: "4px", fontFamily: theme?.typography?.fontFamily }}
      />{" "}
      {title}
    </Button>
  );
};

export const ButtonUIPrevious = ({
  text,
  onClick,
  disable = false,
  type,
  inputStyle,
  variant,
}) => {
  return (
    <Button
      variant={variant}
      type={type}
      disabled={disable}
      sx={{
        width: { xs: "100%", sm: "150px" },
        mt: { xs: 1, sm: 1.5 },
        mb: { xs: 0, sm: 2 },
        height: "45px",
        color: "#FFFFFF",
        "&:hover, &:focus": {
          backgroundColor: "white",
          color: "#FFFFFF",
          backgroundColor: theme?.palette?.secondary?.btnPrimary,
          boxShadow: 0,
        },
        "&:disabled": {
          backgroundColor: theme?.palette?.secondary?.btnPrimary,
          color: "white",
          boxShadow: 0,
        },
        boxShadow: 0,
        fontSize: "12px",
        backgroundColor: theme?.palette?.secondary?.btnPrimary,
        fontWeight: "600",
        fontFamily: theme?.typography?.fontFamily,
        border: "none",
      }}
      onClick={onClick}
    >
      {text}
    </Button>
  );
};

export const ButtonUICancel = ({
  text,
  onClick,
  disable = false,
  type,
  inputStyle,
  variant,
}) => {
  return (
    <Button
      variant={variant}
      type={type}
      disabled={disable}
      sx={{
        width: { xs: "100%", sm: "150px" },
        height: "45px",
        bottom: "0px", // Adjust as needed
        mt: { xs: 1, sm: 1.5 },
        mb: { xs: 0, sm: 2 },
        borderRadius: "4px",
        boxShadow: 0,
        "&:hover, &:focus": {
          boxShadow: 0,
          backgroundColor: theme?.palette?.secondary?.cancelBtn,
          color: theme?.palette?.secondary?.secondary,
        },
        ...theme?.typography?.smallText,
        backgroundColor: theme?.palette?.secondary?.cancelBtn,
        color: theme?.palette?.secondary?.secondary,

        fontFamily: theme?.typography?.fontFamily,
      }}
      onClick={onClick}
    >
      {text}
    </Button>
  );
};

export const ButtonUINext = ({
  text,
  onClick,
  isSubmitting,
  variant,
  disable,
  type,
}) => {
  return (
    <Button
      variant={variant}
      type={type}
      disabled={disable}
      sx={{
        width: { xs: "100%", sm: "150px" },
        height: "45px",
        mt: { xs: 0, sm: 1.5 },
        mb: { xs: 0, sm: 2 },
        "&:hover, &:focus": {
          backgroundColor: theme?.palette?.secondary?.btnPrimary,
          boxShadow: 0,
          color: "#FFFFFF",
        },
        fontSize: "12px",
        backgroundColor: theme?.palette?.secondary?.btnPrimary,
        fontWeight: "600",
        boxShadow: 0,
        color: "#FFFFFF",
        fontFamily: theme?.typography?.fontFamily,
      }}
      onClick={onClick}
    >
      {isSubmitting ? (
        <>
          <LoaderIcon color="#f7f7f7" /> Processing...
        </>
      ) : (
        text
      )}{" "}
    </Button>
  );
};

export const FilterCancelBtn = ({ text, onClick, disabled }) => {
  return (
    <Button
      onClick={onClick}
      variant="contained"
      disabled={disabled}
      sx={{
        width: { xs: "100%", sm: "150px" },
        height: "45px",
        bottom: "0px", // Adjust as needed
        mt: { xs: 1, sm: 1.5 },
        mb: { xs: 0, sm: 2 },
        borderRadius: "4px",
        boxShadow: 0,
        "&:hover, &:focus": {
          boxShadow: 0,
          backgroundColor: theme?.palette?.secondary?.cancelBtn,
          color: theme?.palette?.secondary?.secondary,
        },
        fontSize: "12px",
        backgroundColor: theme?.palette?.secondary?.cancelBtn,
        color: theme?.palette?.secondary?.secondary,
        fontWeight: "600",
        fontFamily: theme?.typography?.fontFamily,
        ...theme?.typography?.smallText,
      }}
    >
      {text}
    </Button>
  );
};

export const FilterSubmitBtn = ({ type, onClick, title }) => {
  return (
    <Button
      type={type}
      variant="contained"
      onClick={onClick}
      sx={{
        width: { xs: "100%", sm: "150px" },
        mt: { xs: 1, sm: 1.5 },
        mb: { xs: 0, sm: 2 },
        height: "45px",
        "&:hover, &:focus": {
          boxShadow: theme?.shadows?.[1],
          backgroundColor: "white",
          color: "#FFFFFF",
          color: "#FFFFFF",
          backgroundColor: theme?.palette?.secondary?.btnPrimary,
          boxShadow: 0,
        },
        "&:disabled": {
          backgroundColor: theme?.palette?.secondary?.btnPrimary,
          color: "white",
          boxShadow: 0,
        },
        boxShadow: 0,
        fontSize: "12px",
        backgroundColor: theme?.palette?.secondary?.btnPrimary,
        fontWeight: "600",
        fontFamily: theme?.typography?.fontFamily,
        ...theme?.typography?.smallText,
      }}
    >
      {title}
    </Button>
  );
};
export const DashboardAddButton = ({ title, onClick, bgColor }) => {
  return (
    <Button
      onClick={onClick}
      variant="contained"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: { xs: "100%", sm: "auto" },
        mt: { xs: 0, sm: 1.5 },
        mb: { xs: 0, sm: 2 },
        // boxShadow: theme?.shadows[0],
        "&:hover, &:focus": {
          // boxShadow: theme?.shadows[1],
          backgroundColor: bgColor,
          boxShadow: 0,
        },
        boxShadow: 0,
        fontSize: "10px",
        backgroundColor: bgColor,
        fontWeight: 400,
        fontFamily: theme?.typography?.fontFamily,
        borderRadius: "4px",
        height: "auto",
        paddingY: "8px",
        paddingX: "14px",
        minHeight: "32px",
      }}
    >
      {title}
    </Button>
  );
};

export const WhiteBgButton = ({ title, onClick, color }) => {
  return (
    <Button
      onClick={onClick}
      variant="contained"
      sx={{
        width: { xs: "100%", sm: "auto" },
        mt: { xs: 0, sm: 1.5 },
        mb: { xs: 0, sm: 2 },
        "&:hover, &:focus": {
          backgroundColor: "#ffffff",
          boxShadow: 0,
        },
        boxShadow: 0,
        fontSize: "10px",
        color: color, // Text color from props
        backgroundColor: "#ffffff",
        fontWeight: 600,
        fontFamily: theme?.typography?.fontFamily,
        borderRadius: "4px",
        padding: "7px",
        border: `1px solid ${color}`, // Corrected border syntax (no space after 1)
      }}
    >
      {title}
    </Button>
  );
};

export const BackBtn = ({ title, link }) => {
  const navigate = useNavigate();
  return (
    <Button
      onClick={() => navigate(link)}
      variant="outlined"
      sx={{
        padding: { xs: "10px 25px", xl: "20px 50px" },
        color: theme?.palette?.secondary?.btnPrimary,
        fontSize: { xs: "10px", xl: "15px" },
        borderColor: "white",
        border: "2px solid",
        borderRadius: "4px",
        background: "transparent",
        "&:hover": {
          border: "2px solid",
          borderColor: theme?.palette?.secondary?.btnPrimary,
          color: theme?.palette?.secondary?.btnPrimary,
          background: "transparent",
        },
      }}
    >
      {title}
    </Button>
  );
};

export const ButtonUISubscription = ({
  text,
  handleClick,
  disable = false,
  type,
  inputStyle,
}) => {
  return (
    <Button
      variant="contined"
      type={type}
      disabled={disable}
      sx={{
        // width: { xs: "100%", sm: "124px" },
        height: "40px",
        mt: { xs: 0, sm: 2 },
        mb: { xs: 0, sm: 2 },
        "&:hover, &:focus": {
          backgroundColor: "#8D43FE",
          boxShadow: 0,
        },
        // Default styles applied here
        fontSize: "12px",
        backgroundColor: "#8D43FE",
        fontWeight: "600",
        color: theme.palette.primary.bodyColor,
        "&:disabled": {
          backgroundColor: "#8D43FE",
          color: theme.palette.primary?.bodyColor,
          fontFamily: theme.typography.fontFamily,
        },
        // Spread any additional styles from the inputStyle prop
        ...inputStyle,
      }}
      onClick={handleClick}
    >
      {text}
    </Button>
  );
};

export const ButtonUIAddSubmit = ({
  text,
  handleClick,
  disable = false,
  type,
  inputStyle,
}) => {
  return (
    <Button
      variant="contined"
      type={type}
      disabled={disable}
      sx={{
        height: "40px",
        mt: { xs: 0, sm: 2 },
        mb: { xs: 0, sm: 2 },
        "&:hover, &:focus": {
          backgroundColor: theme.palette.secondary.btnPrimary,
          boxShadow: 0,
        },
        // Default styles applied here
        fontSize: "12px",
        backgroundColor: theme.palette.secondary.btnPrimary,
        fontWeight: "600",
        color: theme.palette.primary.bodyColor,
        "&:disabled": {
          backgroundColor: theme.palette.secondary.cancelBtn,
          color: theme.palette.primary?.bodyColor,
          fontFamily: theme.typography.fontFamily,
        },
        // Spread any additional styles from the inputStyle prop
        ...inputStyle,
      }}
      onClick={handleClick}
    >
      {text}
    </Button>
  );
};
