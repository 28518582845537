import React from "react";
import MainLayout from "../../layouts/MainLayout";
import { Box, Divider, Grid, Typography } from "@mui/material"; 
import PersonformIcon from "../../Components/Icons/PersonformIcon";
import { theme } from "../../constant/Theme";
import PersonMatterForm from "../../Components/Forms/PersonMatter";
import { CustomStepper, PaperComponent } from "../../Components/Card/SettingCard";
const steps = [{ text: "Matter Details", label: "Matter & Role information", icon: <PersonformIcon /> }];
const AddNewPersonMatter = () => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const isStepOptional = (step) => {
    return step === 1;
  };
  const isStepSkipped = (step) => {
    return skipped.has(step);
  };
  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };
  
  return (
    <MainLayout>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5" sx={{ color: theme?.palette?.primary?.main }}>
              Persons /&nbsp;
              <Box component="span" sx={{ color: theme?.palette?.primary?.subPrimary }}>
                Assign New Matter
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <PaperComponent>
              <Box
                sx={{
                  width: "100%",
                   color: "lightgray",
                 }}
              >
                <Grid container>
                  <Grid item xs={12} style={{ background: theme?.palette?.primary?.sidebar, borderRadius: "8px" }}>
                    <CustomStepper steps={steps} activeStep={activeStep} isStepSkipped={isStepSkipped} theme={theme} />

                    <React.Fragment>
                      <PersonMatterForm activeStep={activeStep} handleNext={handleNext} handleSkip={handleSkip} handleBack={handleBack} steps={steps} isStepOptional={isStepOptional} />
                    </React.Fragment>
                  </Grid>
                </Grid>
              </Box>
            </PaperComponent>
          </Grid>
        </Grid>
      </Grid>
    </MainLayout>
  );
};

export default AddNewPersonMatter;
