import React, { useCallback, useMemo } from "react";
import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SvgIcon,
  TextField,
  Typography,
} from "@mui/material";
import { allCategory } from "../../../constant/GeneratePleading";
import ClearIcon from "@mui/icons-material/Clear";
import { styles } from "../../../pages/Queries/Style";
import useQuerieDropdownHook from "./useQuerieDropdownHook";
import useChatMessages from "../../../hooks/useChatMessages";
import { theme } from "../../../constant/Theme";

const QuerieDropdwon = ({ formik, chatHistory }) => {
  const {
    allCase,
    allPersons,
    userFile,
    allType,
    querierCondtion,
    isCasesLoading,
    isPersonsLoading,
    isFilesLoading,
  } = useQuerieDropdownHook(formik);

  const { isLoading, allChat: data, newMessageId } = useChatMessages();

  // Memoized helper function
  const removeIdAndAll = useCallback((array, idToRemove) => {
    return array.filter((id) => id !== idToRemove && id !== "all");
  }, []);

  // Memoized menu props
  const MenuProps = useMemo(
    () => ({
      PaperProps: {
        style: {
          width: 250,
          maxHeight: 48 * 4.5 + 8,
        },
      },
    }),
    []
  );

  // Handlers with optimized state updates
  const handleFillingPartySelect = useCallback(
    (value) => {
      if (value !== formik.values.fillingParty) {
        formik.setFieldValue("fillingParty", value);
        formik.setFieldValue("personsId", "");
        formik.setFieldValue("witnessData", null);
      }
    },
    [formik]
  );

  const handleWitnessSelect = useCallback(
    (value) => {
      if (value === formik.values.fillingParty) {
        // Reset the fields if the same value is selected
        formik.setFieldValue("fillingParty", "");
        formik.setFieldValue("personsId", "");
        formik.setFieldValue("witnessData", null);
      } else {
        // Set the fields when a new value is selected
        formik.setFieldValue("fillingParty", value);
        formik.setFieldValue("personsId", "");
        formik.setFieldValue("witnessData", null);
      }
    },
    [formik] // Depend on formik to avoid unnecessary recalculations
  );
  const handleMenuItemClick = useCallback(
    (value) => {
      // Ensure the value of category is an array for processing
      const category = Array.isArray(formik.values.category)
        ? formik.values.category
        : [formik.values.category].filter(Boolean);

      if (category.length === 1 && category[0] === value) {
        // Reset category if the same value is selected again
        formik.setFieldValue("category", "");
      } else if (!category.includes(value)) {
        // Add the value if it's not already present
        formik.setFieldValue("category", [...category, value]);
      } else {
        // Remove the value if it's already present
        formik.setFieldValue(
          "category",
          category.filter((item) => item !== value)
        );
      }
    },
    [formik]
  );

  const handleTypeSelect = useCallback(
    (value) => {
      if (value !== formik.values.type) {
        formik.setFieldValue("type", value);
      }
    },
    [formik]
  );

  const handleCategoryChange = useCallback(
    (event) => {
      const value = event.target.value;
      if (value !== formik.values.category) {
        formik.setFieldValue("category", value);
        formik.setFieldValue("items", []);
        formik.setFieldValue("personsId", "");

        if (value === "client") {
          formik.setFieldValue("sources", 1);
        }
      }
    },
    [formik]
  );
  const filteredUserFiles = useMemo(
    () =>
      formik.values.items
        ? userFile.filter((file) => formik.values.items.includes(file.fileUrl))
        : [],
    [formik.values.items, userFile]
  );

  // Memoize styles
  const commonStyles = useMemo(
    () => ({
      color: theme?.palette?.primary?.placeholderColor,
      "& .MuiOutlinedInput-root": {
        borderRadius: "5px",
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: theme?.palette?.secondary?.inputColor,
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
          borderColor: theme?.palette?.secondary?.hoverColor,
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: theme?.palette?.secondary?.inputColor,
        },
      },
      "& .MuiSvgIcon-root": {
        color: theme?.palette?.primary?.placeholderColor,
      },
    }),
    [theme]
  );

  // Handle changes in Autocomplete
  const handleItemsChange = useCallback(
    (event, newValue) => {
      const fileUrls = newValue.map((item) => item?.fileUrl);
      formik.setFieldValue("items", fileUrls);
    },
    [formik]
  );

  // Handle changes in Select
  const handleFillingPartyChange = useCallback(
    (e) => {
      const selectedPerson = allPersons.find(
        (v) => v.personsId === e.target.value
      );
      formik.setFieldValue("fillingParty", e.target.value);
      formik.setFieldValue("personsId", e.target.value);
      formik.setFieldValue("witnessData", selectedPerson?.witnessData || null);
    },
    [formik, allPersons]
  );
  const memoizedPersons = useMemo(() => {
    return allPersons.map((person) => ({
      id: person.personsId,
      name: person.personName,
      witnessData: person.witnessData, // Cache witnessData for future use
    }));
  }, [allPersons]);

  // Use callback to prevent function recreation
  const handlePersonSelect = useCallback(
    (selectedValue) => {
      formik.setFieldValue("filter", null);
      const currentPerson = formik.values.fillingParty;

      // If the same person is selected, reset the fields
      if (selectedValue === currentPerson) {
        formik.setFieldValue("witnessData", null); // Reset witnessData
        formik.setFieldValue("fillingParty", null); // Reset fillingParty
        formik.setFieldValue("personsId", null); // Reset personsId
      } else {
        const selectedPerson = memoizedPersons.find(
          (person) => person.id === selectedValue
        );
        const filterData = selectedPerson?.witnessData;

        // Update form values once
        formik.setFieldValue("witnessData", filterData);
        formik.setFieldValue("fillingParty", selectedValue);
        formik.setFieldValue("personsId", selectedValue);
      }
    },
    [formik, memoizedPersons] // Depend on memoizedPersons to avoid unnecessary recalculations
  );

  const memoizedUserFile = useMemo(() => {
    return userFile.map((file) => ({
      fileUrl: file.fileUrl,
      fileName: file.fileName,
    }));
  }, [userFile]);

  // Memoize the filtered value for items
  const memoizedValue = useMemo(() => {
    if (!formik.values.items) return [];
    return memoizedUserFile.filter((file) =>
      formik.values.items.includes(file.fileUrl)
    );
  }, [formik.values.items, memoizedUserFile]);

  // Memoize the onChange handler to prevent re-creation on each render
  const memoizedOptions = useMemo(() => {
    return allType?.map((row) =>
      row.length >= 25 ? row.substring(0, 25) + "..." : row
    );
  }, [allType]);

  // Memoized onChange handler to prevent unnecessary re-creations
  const handleTypeChange = useCallback(
    (e) => {
      const selectedValue = e.target.value;

      if (selectedValue === formik.values.type) {
        // Reset the field if the same value is selected
        formik.setFieldValue("type", "");
      } else {
        // Set the field value to the new selected value
        formik.setFieldValue("type", selectedValue);
      }
    },
    [formik] // Only recreate when formik changes
  );
  const backgroundColorStyle = useMemo(() => {
    return formik.values.category === "client" ||
      formik.values.caseId[0] === undefined ||
      formik?.values?.category
      ? theme?.palette?.primary?.subSecondary
      : "";
  }, [
    formik.values.category,
    formik.values.caseId,
    theme?.palette?.primary?.subSecondary,
  ]);

  // Memoize styles for InputLabel
  const inputLabelStyle = useMemo(
    () => ({
      color: theme?.palette?.primary?.placeholderColor,
      "&.Mui-focused": {
        color: theme?.palette?.primary?.placeholderColor,
      },
      "&.Mui-disabled": {
        color: theme?.palette?.primary?.placeholderColor,
      },
    }),
    [theme?.palette?.primary?.placeholderColor]
  );

  // Memoize styles for Select component
  const selectStyle = useMemo(
    () => ({
      "&.Mui-disabled": {
        color: theme?.palette?.primary?.placeholderColor,
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: theme?.palette?.secondary?.inputColor,
      },
      "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
        borderColor: theme?.palette?.secondary?.inputColor,
      },
      "& .MuiSvgIcon-root": {
        color: theme?.palette?.primary?.placeholderColor,
      },
      "&.Mui-disabled .MuiSvgIcon-root": {
        color: theme?.palette?.primary?.placeholderColor,
      },
    }),
    [
      theme?.palette?.primary?.placeholderColor,
      theme?.palette?.secondary?.inputColor,
    ]
  );

  // Memoize styles for MenuProps
  const menuPropsStyle = useMemo(
    () => ({
      PaperProps: {
        sx: {
          backgroundColor: theme?.palette?.primary?.subSecondary,
          color: theme?.palette?.primary?.placeholderColor,
          maxHeight: 200,
          overflowY: "auto",
          "& .MuiMenuItem-root": {
            "&:hover": {
              backgroundColor: theme?.palette?.primary?.tertiaryShades,
              color: theme?.palette?.primary?.placeholderColor,
            },
          },
        },
      },
    }),
    [
      theme?.palette?.primary?.subSecondary,
      theme?.palette?.primary?.placeholderColor,
      theme?.palette?.primary?.tertiaryShades,
    ]
  );

  return (
    <Grid item xs={12} style={{ paddingTop: "0" }}>
      <Box component="form" noValidate onSubmit={formik.handleSubmit}>
        {/* Category Dropdown */}
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={3}>
            <FormControl
              variant="outlined"
              size="small"
              fullWidth
              error={formik.touched.case && Boolean(formik.errors.case)}
              sx={styles?.input}
            >
              <Autocomplete
                id="case"
                multiple
                options={allCase}
                getOptionLabel={(option) => option.title}
                autoComplete
                size="small"
                includeInputInList
                value={useMemo(() => {
                  return formik.values.caseId?.length === allCase.length
                    ? [{ _id: "all", title: "All" }]
                    : allCase?.filter((option) =>
                        formik.values.caseId?.includes(option._id)
                      );
                }, [formik.values.caseId, allCase])}
                onChange={useCallback(
                  (event, newValue) => {
                    formik.setFieldValue("category", "");
                    formik.setFieldValue("personsId", []);
                    formik.setFieldValue("filter", []);

                    const selectedIds = newValue.map((option) => option._id);
                    const isAllSelected = selectedIds.includes("all");
                    const previouslySelected = formik.values.caseId || [];

                    if (isAllSelected) {
                      const allCaseIds = allCase.map((option) => option._id);
                      // Check if "All" was already selected
                      const previouslyIncludesAll =
                        previouslySelected.includes("all");

                      if (
                        isAllSelected &&
                        newValue[1]?._id !== "all" &&
                        newValue.length > 1
                      ) {
                        let caseIds = formik.values.caseId;
                        let arr = removeIdAndAll(caseIds, newValue[1]?._id);
                        formik.setFieldValue("caseId", arr);
                      }
                      if (previouslyIncludesAll && newValue[1]?._id === "all") {
                        // If "All" was previously selected, deselect all
                        formik.setFieldValue("caseId", []);
                      } else if (newValue[newValue.length - 1]?._id === "all") {
                        formik.setFieldValue("caseId", allCaseIds);
                      }
                    } else {
                      // If "All" is not selected, just update with selected IDs
                      formik.setFieldValue("caseId", selectedIds);
                    }
                  },
                  [formik, allCase] // Adding dependencies to re-run the callback only when formik.values.caseId or allCase changes
                )}
                onBlur={formik.handleBlur}
                renderInput={useCallback(
                  (params) => (
                    <TextField
                      {...params}
                      label="Matters"
                      variant="outlined"
                      fullWidth
                      error={formik.touched.case && Boolean(formik.errors.case)}
                      helperText={
                        formik.touched.case && formik.errors.case
                          ? formik.errors.case
                          : ""
                      }
                      InputLabelProps={{
                        sx: {
                          color: theme?.palette?.primary?.main,
                          "&.Mui-focused": {
                            color: theme?.palette?.primary?.placeholderColor,
                          },
                        },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          color: theme?.palette?.primary?.placeholderColor,
                          "& fieldset": {
                            borderColor: theme?.palette?.secondary?.inputColor,
                          },
                          "&:hover fieldset": {
                            borderColor: theme?.palette?.secondary?.hoverColor,
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: theme?.palette?.primary?.inputColor,
                          },
                        },
                        "& .MuiSvgIcon-root": {
                          color: theme?.palette?.primary?.placeholderColor,
                        },
                      }}
                    />
                  ),
                  [formik, theme]
                )}
                renderOption={useCallback(
                  (props, option, { selected }) => {
                    const isAllSelected =
                      formik.values.caseId?.length === allCase.length;

                    return (
                      <li {...props}>
                        <Checkbox
                          checked={selected || isAllSelected}
                          sx={{
                            color: isAllSelected
                              ? theme?.palette?.primary?.placeholderColor
                              : theme?.palette?.primary?.placeholderColor,
                            backgroundColor: "transparent",
                            "&.Mui-checked": {
                              color: isAllSelected ? "#686DFC" : "#686DFC",
                              backgroundColor: "transparent",
                            },
                            "& .MuiSvgIcon-root": {
                              fontSize: "1.2rem",
                            },
                          }}
                        />
                        <Typography
                          sx={{
                            fontSize: "14px",
                            color: theme?.palette?.primary?.placeholderColor,
                          }}
                          paddingLeft={1}
                        >
                          {option.title}
                        </Typography>
                      </li>
                    );
                  },
                  [formik.values.caseId, allCase, theme]
                )}
                PaperComponent={(props) => (
                  <Paper
                    {...props}
                    sx={{
                      backgroundColor: theme?.palette?.primary?.subSecondary,
                      color: theme?.palette?.primary?.placeholderColor,
                    }}
                  />
                )}
                ListboxProps={{
                  sx: {
                    maxHeight: 200,
                    overflowY: "auto",
                    "& .MuiAutocomplete-option": {
                      display: "flex",
                      alignItems: "center",
                      "&:hover": {
                        backgroundColor:
                          theme?.palette?.primary?.tertiaryShades,
                        color: theme?.palette?.primary?.placeholderColor,
                      },
                    },
                    "& .MuiAutocomplete-noOptions": {
                      color: "red",
                      textAlign: "center",
                    },
                  },
                }}
                renderTags={useCallback(
                  (value, getTagProps) => {
                    const maxVisibleChips = 1;
                    if (formik.values.caseId?.length === allCase.length) {
                      return (
                        <Chip
                          label="All"
                          deleteIcon={
                            <SvgIcon
                              sx={{
                                color: "#686DFC",
                                width: "15px",
                                height: "15px",
                              }}
                            >
                              <ClearIcon
                                sx={{
                                  width: "15px",
                                  height: "15px",
                                  color: "#686DFC",
                                }}
                              />
                            </SvgIcon>
                          }
                          sx={{
                            backgroundColor:
                              theme?.palette?.primary?.tertiaryShades,
                            color: theme?.palette?.primary?.placeholderColor,
                            height: "25px",
                            textTransform: "capitalize",
                          }}
                        />
                      );
                    }

                    const extraCount = value.length - maxVisibleChips;
                    return (
                      <>
                        {value
                          .slice(0, maxVisibleChips)
                          .map((option, index) => {
                            const chipProps = getTagProps({ index });
                            return (
                              <Chip
                                key={chipProps.key} // Explicitly pass the key here
                                label={option?.title?.slice(0, 5)}
                                deleteIcon={
                                  <SvgIcon
                                    sx={{
                                      color: "#686DFC",
                                      width: "15px",
                                      height: "15px",
                                    }}
                                  >
                                    <ClearIcon
                                      sx={{
                                        width: "15px",
                                        height: "15px",
                                        color: "#686DFC",
                                      }}
                                    />
                                  </SvgIcon>
                                }
                                sx={{
                                  backgroundColor:
                                    theme?.palette?.primary?.tertiaryShades,
                                  color:
                                    theme?.palette?.primary?.placeholderColor,
                                  height: "25px",
                                  textTransform: "capitalize",
                                  borderRadius: "2px",
                                  width: "80px",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  fontSize: "0.875rem",
                                }}
                                {...chipProps} // Spread the rest of the props
                              />
                            );
                          })}
                        {extraCount > 0 && (
                          <Chip
                            label={`${extraCount} more`}
                            sx={{
                              backgroundColor:
                                theme?.palette?.primary?.tertiaryShades,
                              color: theme?.palette?.primary?.placeholderColor,
                              height: "25px",
                              textTransform: "capitalize",
                              borderRadius: "2px",
                              width: "80px",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              fontSize: "0.875rem",
                            }}
                          />
                        )}
                      </>
                    );
                  },
                  [formik.values.caseId, allCase, theme]
                )}
                noOptionsText="No matter found"
              />
            </FormControl>
          </Grid>
          {/* category */}
          <Grid item sm={3}>
            <FormControl
              fullWidth
              variant="outlined"
              size="small"
              error={formik.touched.category && Boolean(formik.errors.category)}
              sx={{
                ...styles?.input,
                backgroundColor:
                  formik.values.caseId[0] === undefined
                    ? theme?.palette?.primary?.subSecondary
                    : "",
              }}
            >
              <InputLabel
                id="category-label"
                sx={{
                  color: theme?.palette?.primary?.placeholderColor, // Set text color

                  "&.Mui-focused": {
                    color: theme?.palette?.primary?.placeholderColor, // Set text color
                  },
                  "&.Mui-disabled": {
                    color: theme?.palette?.primary?.placeholderColor,
                  },
                }}
              >
                Category
              </InputLabel>
              <Select
                labelId="category-label"
                id="category"
                name="category"
                label="Category"
                disabled={formik.values.caseId[0] === undefined}
                value={formik.values.category || ""}
                onBlur={formik.handleBlur}
                onChange={handleCategoryChange}
                sx={{
                  "&.Mui-disabled": {
                    color: theme?.palette?.secondary?.inputColor,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: theme?.palette?.secondary?.inputColor,
                  },
                  "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
                    borderColor: theme?.palette?.secondary?.inputColor,
                  },
                  "& .MuiSvgIcon-root": {
                    color: theme?.palette?.primary?.placeholderColor, // Set text color
                  },
                  "&.Mui-disabled .MuiSvgIcon-root": {
                    color: theme?.palette?.primary?.placeholderColor, // Set text color
                  },
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      backgroundColor: theme?.palette?.primary?.subSecondary,
                      color: theme?.palette?.primary?.placeholderColor, // Set text color

                      maxHeight: 200,
                      overflowY: "auto",
                      "& .MuiMenuItem-root": {
                        "&:hover": {
                          backgroundColor:
                            theme?.palette?.primary?.tertiaryShades,
                          color: theme?.palette?.primary?.placeholderColor,
                        },
                      },
                    },
                  },
                }}
              >
                <MenuItem value="" disabled>
                  <Typography
                    sx={{
                      fontStyle: "normal",
                      color: theme?.palette?.primary?.dropDownColor,
                    }}
                  >
                    Select Category
                  </Typography>
                </MenuItem>
                {allCategory
                  ?.sort((a, b) => a.title.localeCompare(b.title)) // Sort by title
                  .map((value, index) => (
                    <MenuItem
                      value={value.values}
                      key={index}
                      onClick={() => handleMenuItemClick(value?.values)}
                    >
                      {value.title}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          {/* person */}
          {formik.values.category === "depositions" ? (
            <Grid item sm={3}>
              <FormControl
                variant="outlined"
                size="small"
                fullWidth
                error={
                  formik.touched.fillingParty &&
                  Boolean(formik.errors.fillingParty)
                }
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "5px",
                    color: theme?.palette?.primary?.placeholderColor, // Set text color
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: theme?.palette?.secondary?.inputColor, // Normal border color
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: theme?.palette?.secondary?.hoverColor, // Border color on hover
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: theme?.palette?.secondary?.inputColor, // Border color on focus
                    },
                  },
                  "& .MuiSvgIcon-root": {
                    color: theme?.palette?.primary?.placeholderColor,
                  },
                }}
              >
                <InputLabel
                  id="fillingParty-label"
                  sx={{
                    color: theme?.palette?.primary?.placeholderColor,
                    "&.Mui-focused": {
                      color: theme?.palette?.primary?.placeholderColor, // Change label color when focused
                    },
                  }}
                >
                  Persons
                </InputLabel>
                <Select
                  labelId="fillingParty-label"
                  id="fillingParty"
                  autoComplete="off"
                  name="fillingParty"
                  label="Persons"
                  value={formik.values.fillingParty || ""} // Ensure this is the correct field
                  onBlur={formik.handleBlur}
                  onChange={(e) => handlePersonSelect(e.target.value)}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        backgroundColor: theme?.palette?.primary?.subSecondary,
                        color: theme?.palette?.primary?.placeholderColor, // Set text color
                        maxHeight: 200,
                        overflowY: "auto",
                        "& .MuiMenuItem-root": {
                          "&:hover": {
                            backgroundColor:
                              theme?.palette?.primary?.tertiaryShades,
                            color: theme?.palette?.primary?.placeholderColor,
                          },
                        },
                      },
                    },
                  }}
                  renderValue={(selected) => {
                    // Check if a person is selected
                    if (selected) {
                      const selectedPerson = memoizedPersons.find(
                        (person) => person.id === selected
                      );
                      return selectedPerson ? (
                        selectedPerson.name
                      ) : (
                        <em>Select person</em>
                      );
                    }
                    return (
                      <Typography
                        sx={{
                          fontStyle: "normal",
                          color: theme?.palette?.primary?.dropDownColor,
                        }}
                      >
                        Select Person
                      </Typography>
                    ); // Default placeholder
                  }}
                  sx={{
                    // Add these styles to handle long text
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  <MenuItem value="" disabled>
                    <Typography
                      sx={{
                        fontStyle: "normal",
                        color: theme?.palette?.primary?.dropDownColor,
                      }}
                    >
                      Select Person
                    </Typography>
                  </MenuItem>
                  {memoizedPersons.map((person) => (
                    <MenuItem
                      key={person.id}
                      value={person.id}
                      onClick={() => handlePersonSelect(person.id)}
                    >
                      {person.name}
                    </MenuItem>
                  ))}
                </Select>
                {formik.touched.fillingParty && formik.errors.fillingParty && (
                  <FormHelperText>{formik.errors.fillingParty}</FormHelperText>
                )}
              </FormControl>
            </Grid>
          ) : querierCondtion.includes(formik.values.category) ? (
            <Grid item sm={3}>
              <FormControl
                variant="outlined"
                size="small"
                fullWidth
                error={formik.touched?.type && Boolean(formik.errors?.type)}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    color: theme?.palette?.primary?.placeholderColor,
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: theme?.palette?.secondary?.inputColor,
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: theme?.palette?.secondary?.hoverColor,
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: theme?.palette?.secondary?.inputColor, // Border color on focus
                    },
                  },
                  "& .MuiSvgIcon-root": {
                    color: theme?.palette?.primary?.placeholderColor,
                  },
                }}
              >
                <InputLabel
                  id="type-label"
                  sx={{
                    color: theme?.palette?.primary?.placeholderColor,
                    "&.Mui-focused": {
                      color: theme?.palette?.primary?.placeholderColor, // Change label color when focused
                    },
                  }}
                >
                  {formik.values.category === "person" ? "Role" : "Type"}
                </InputLabel>
                <Select
                  labelId="type-label"
                  id="type"
                  label="Type"
                  autoComplete="off"
                  name="type"
                  value={formik.values?.type || ""} // Controlled value
                  onBlur={formik.handleBlur}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        backgroundColor: theme?.palette?.primary?.subSecondary,
                        color: theme?.palette?.primary?.placeholderColor,
                        maxHeight: 200,
                        overflowY: "auto",
                        "& .MuiMenuItem-root": {
                          "&:hover": {
                            backgroundColor:
                              theme?.palette?.primary?.tertiaryShades,
                            color: theme?.palette?.primary?.placeholderColor,
                          },
                        },
                      },
                    },
                  }}
                >
                  <MenuItem value="" disabled>
                    <Typography
                      sx={{
                        fontStyle: "normal",
                        color: theme?.palette?.primary?.dropDownColor,
                      }}
                    >
                      Select{" "}
                      {formik.values.category === "person" ? "Role" : "Type"}
                    </Typography>
                  </MenuItem>
                  {memoizedOptions?.map((row, index) => (
                    <MenuItem
                      key={index}
                      value={row}
                      onClick={() =>
                        handleTypeChange({ target: { value: row } })
                      } // Use onClick to trigger handleTypeChange
                    >
                      {row}
                    </MenuItem>
                  ))}
                </Select>
                {formik.touched.type && formik.errors.type && (
                  <FormHelperText>{formik.errors.type}</FormHelperText>
                )}
              </FormControl>
            </Grid>
          ) : (
            <Grid item sm={3}>
              <FormControl
                fullWidth
                variant="outlined"
                size="small"
                sx={{ backgroundColor: backgroundColorStyle }}
              >
                <InputLabel id="filter-label" sx={inputLabelStyle}>
                  Filter 1
                </InputLabel>
                <Select
                  labelId="filter-label"
                  id="filter"
                  name="filter"
                  disabled
                  label="Filter 1"
                  value={formik.values.personsId || ""}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  sx={selectStyle}
                  MenuProps={menuPropsStyle}
                />
              </FormControl>
            </Grid>
          )}
          {/* Item Dropdown */}
          {formik.values.category === "depositions" ? (
            <Grid item sm={3}>
              <FormControl
                fullWidth
                variant="outlined"
                size="small"
                error={formik.touched.items && Boolean(formik.errors.items)}
                sx={styles?.input}
              >
                <Autocomplete
                  size="small"
                  multiple
                  disableClearable
                  id="items-autocomplete"
                  options={memoizedUserFile} // Use memoized userFile array
                  getOptionLabel={(option) => option?.fileName || ""}
                  value={memoizedValue} // Use memoized filtered value
                  onChange={handleItemsChange} // Memoized onChange handler
                  disableCloseOnSelect
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Items"
                      name="items"
                      error={
                        formik.touched.items && Boolean(formik.errors.items)
                      }
                      onBlur={formik.handleBlur}
                      InputLabelProps={{
                        style: {
                          color: theme?.palette?.primary?.placeholderColor,
                        },
                      }}
                      sx={{
                        color: theme?.palette?.primary?.placeholderColor,
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "5px",
                          width: "100%",
                          color: theme?.palette?.primary?.placeholderColor,
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: theme?.palette?.secondary?.inputColor,
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: theme?.palette?.secondary?.hoverColor,
                          },
                        },
                        "& .MuiSvgIcon-root": {
                          color: theme?.palette?.primary?.placeholderColor,
                        },
                        "&.Mui-focused": {
                          color: theme?.palette?.primary?.placeholderColor,
                        },
                      }}
                    />
                  )}
                  PaperComponent={({ children }) => (
                    <Paper
                      sx={{
                        backgroundColor: theme?.palette?.primary?.subSecondary,
                        color: theme?.palette?.primary?.placeholderColor,
                        maxHeight: 200,
                        overflowY: "auto",
                        "& .MuiMenuItem-root": {
                          "&:hover": {
                            backgroundColor:
                              theme?.palette?.primary?.tertiaryShades,
                            color: theme?.palette?.primary?.placeholderColor,
                          },
                        },
                      }}
                    >
                      {children}
                    </Paper>
                  )}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        {...getTagProps({ index })}
                        label={option?.fileName}
                        deleteIcon={
                          <SvgIcon
                            sx={{
                              color: theme?.palette?.primary?.placeholderColor,
                              width: "15px",
                              height: "15px",
                            }}
                          >
                            <ClearIcon
                              sx={{
                                width: "15px",
                                height: "15px",
                                color:
                                  theme?.palette?.primary?.placeholderColor,
                              }}
                            />
                          </SvgIcon>
                        }
                        sx={{
                          backgroundColor:
                            theme?.palette?.primary?.subSecondary,
                          color: theme?.palette?.primary?.placeholderColor,
                          textTransform: "capitalize",
                          borderRadius: "2px",
                          width: "70px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          fontSize: "0.875rem",
                        }}
                      />
                    ))
                  }
                  renderOption={(props, option, { selected }) => (
                    <li
                      {...props}
                      key={option.fileUrl}
                      style={{
                        color: theme?.palette?.primary?.placeholderColor,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Checkbox
                        checked={selected}
                        sx={{
                          color: theme?.palette?.primary?.placeholderColor,
                          width: "30px",
                          height: "30px",
                          backgroundColor: "transparent",
                          "&.Mui-checked": {
                            color: "#686DFC",
                            backgroundColor: "transparent",
                          },
                          "& .MuiSvgIcon-root": {
                            fontSize: "1.2rem",
                          },
                        }}
                      />
                      <Typography sx={{ fontSize: "14px" }} paddingLeft={1}>
                        {option.fileName}
                      </Typography>
                    </li>
                  )}
                  sx={{
                    color: theme?.palette?.primary?.placeholderColor,
                    "& .MuiOutlinedInput-root": {
                      color: theme?.palette?.primary?.placeholderColor,
                      "&.Mui-focused fieldset": {
                        borderColor: theme?.palette?.primary?.placeholderColor,
                      },
                    },
                    "& .MuiAutocomplete-popupIndicator": {
                      color: theme?.palette?.primary?.placeholderColor,
                    },
                    "& .MuiAutocomplete-clearIndicator": {
                      color: theme?.palette?.primary?.placeholderColor,
                    },
                    "& .MuiListSubheader-root, .MuiAutocomplete-option": {
                      backgroundColor: "#323249",
                      color: theme?.palette?.primary?.placeholderColor,
                    },
                  }}
                />
                {formik.touched.items && formik.errors.items && (
                  <FormHelperText>{formik.errors.items}</FormHelperText>
                )}
              </FormControl>
            </Grid>
          ) : querierCondtion.includes(
              formik.values.category || chatHistory?.moduleName
            ) &&
            (formik.values?.type || chatHistory?.type) ? (
            <Grid item sm={3}>
              <FormControl
                variant="outlined"
                size="small"
                fullWidth
                error={
                  formik.touched.fillingParty &&
                  Boolean(formik.errors.fillingParty)
                }
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "5px",
                    color: theme?.palette?.primary?.placeholderColor, // Set text color
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: theme?.palette?.secondary?.inputColor, // Normal border color
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: theme?.palette?.secondary?.hoverColor, // Border color on hover
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: theme?.palette?.secondary?.inputColor, // Border color on focus
                    },
                  },
                  "& .MuiSvgIcon-root": {
                    color: theme?.palette?.primary?.placeholderColor,
                  },
                }}
              >
                <InputLabel
                  id="fillingParty-label"
                  sx={{
                    color: theme?.palette?.primary?.main,
                    "&.Mui-focused": {
                      color: theme?.palette?.primary?.main, // Change label color when focused
                    },
                  }}
                >
                  Witness Name
                </InputLabel>
                <Select
                  labelId="fillingParty-label"
                  id="fillingParty"
                  autoComplete="off"
                  name="fillingParty"
                  label="witness name"
                  value={
                    formik.values.personsId ||
                    chatHistory?.person_id ||
                    formik.values.fillingParty
                  }
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    const filterData = allPersons?.find(
                      (v) => v.personsId === e.target.value
                    )?.witnessData;
                    formik.setFieldValue("fillingParty", e.target.value);
                    formik.setFieldValue("personsId", e.target.value);
                    formik.setFieldValue("witnessData", filterData);
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        backgroundColor: theme?.palette?.primary?.subSecondary,
                        color: theme?.palette?.primary?.placeholderColor, // Set text color

                        maxHeight: 200,
                        overflowY: "auto",
                        "& .MuiMenuItem-root": {
                          "&:hover": {
                            backgroundColor:
                              theme?.palette?.primary?.tertiaryShades,
                            color: theme?.palette?.primary?.placeholderColor,
                          },
                        },
                      },
                    },
                  }}
                >
                  <MenuItem value="" disabled>
                    <Typography
                      sx={{
                        fontStyle: "normal",
                        color: theme?.palette?.primary?.dropDownColor,
                      }}
                    >
                      Select Witness Name
                    </Typography>
                  </MenuItem>
                  {allPersons?.map((row, index) => (
                    <MenuItem
                      key={index}
                      value={row.personsId}
                      onClick={() => handleWitnessSelect(row.personsId)}
                    >
                      {`${row?.personName}`}
                    </MenuItem>
                  ))}
                </Select>
                {formik.touched.fillingParty && formik.errors.fillingParty && (
                  <FormHelperText>{formik.errors.fillingParty}</FormHelperText>
                )}
              </FormControl>
            </Grid>
          ) : (
            <Grid item sm={3}>
              <FormControl
                fullWidth
                variant="outlined"
                size="small"
                sx={{
                  color: theme?.palette?.primary?.placeholderColor,
                  backgroundColor:
                    formik.values.category === "client" ||
                    formik.values.category === "discovery" ||
                    formik.values.category === "exhibit" ||
                    formik.values.category === "medical" ||
                    formik.values.category === "person" ||
                    formik?.values?.category === "pleading" ||
                    formik.values.caseId[0] === undefined ||
                    formik?.values?.category
                      ? theme?.palette?.primary?.subSecondary
                      : "",
                }}
              >
                <InputLabel
                  id="filter-label"
                  sx={{
                    color: theme?.palette?.primary?.placeholderColor,
                    "&.Mui-focused": {
                      color: theme?.palette?.primary?.placeholderColor,
                    },
                    "&.Mui-disabled": {
                      color: theme?.palette?.primary?.placeholderColor,
                    },
                  }}
                >
                  Filter 2
                </InputLabel>
                <Select
                  labelId="filter-label"
                  id="filter"
                  name="filter"
                  disabled
                  label="filter 2"
                  value={formik.values.filter || ""}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  sx={{
                    "&.Mui-disabled": {
                      color: theme?.palette?.primary?.placeholderColor,
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: theme?.palette?.secondary?.inputColor,
                    },
                    "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
                      borderColor: theme?.palette?.secondary?.inputColor,
                    },
                    "& .MuiSvgIcon-root": {
                      color: theme?.palette?.primary?.placeholderColor,
                    },
                    "&.Mui-disabled .MuiSvgIcon-root": {
                      color: theme?.palette?.primary?.placeholderColor,
                    },
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        backgroundColor: theme?.palette?.primary?.subSecondary,
                        color: theme?.palette?.primary?.placeholderColor, // Set text color

                        maxHeight: 200,
                        overflowY: "auto",
                        "& .MuiMenuItem-root": {
                          "&:hover": {
                            backgroundColor:
                              theme?.palette?.primary?.tertiaryShades,
                            color: theme?.palette?.primary?.placeholderColor,
                          },
                        },
                      },
                    },
                  }}
                ></Select>
              </FormControl>
            </Grid>
          )}

          {/* Autocomplete */}

          <Grid item sm={3}>
            <FormControl
              fullWidth
              sx={{
                ...styles?.inpuDisable,
                backgroundColor:
                  formik.values.category === "client" ||
                  formik.values.category === "person" ||
                  formik?.values?.category === "medical"
                    ? theme?.palette?.primary?.subSecondary
                    : "",
              }}
            >
              <Autocomplete
                sx={{ color: theme?.palette?.primary?.placeholderColor }}
                id="sources"
                disabled={
                  formik.values.category === "client" ||
                  formik.values.category === "person" ||
                  formik.values.category === "medical"
                }
                disableClearable={true}
                options={
                  data.length > 0
                    ? [0, 1, 2, 3, 4, 5, 6, 7, 8]
                    : [1, 2, 3, 4, 5, 6, 7, 8]
                }
                getOptionLabel={(option) => `${option}`}
                value={
                  formik.values.category === "person"
                    ? 1
                    : formik.values.sources !== undefined &&
                      formik.values.sources !== ""
                    ? formik.values.sources
                    : 4
                }
                onChange={(event, value) => {
                  formik.setFieldValue("sources", value);
                }}
                isOptionEqualToValue={(option, value) => option === value}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    label="Number of Sources"
                    error={
                      formik.touched.sources && Boolean(formik.errors.sources)
                    }
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "5px",
                        width: "100%",
                        color: theme?.palette?.primary?.placeholderColor,
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: theme?.palette?.secondary?.inputColor,
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor:
                            formik.values.category === "client" ||
                            formik.values.category === "person" ||
                            formik?.values?.category === "medical"
                              ? ""
                              : theme?.palette?.primary?.placeholderColor,
                        },

                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: theme?.palette?.secondary?.inputColor, // Normal border color
                        },
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor:
                            theme?.palette?.primary?.placeholderColor,
                        },
                      },
                      "& .MuiSvgIcon-root": {
                        color: theme?.palette?.primary?.placeholderColor,
                      },
                      "&.Mui-focused": {
                        color: theme?.palette?.primary?.placeholderColor, // Change label color when focused
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        color: theme?.palette?.primary?.placeholderColor,
                      },
                    }}
                  />
                )}
                PaperComponent={({ children }) => (
                  <Paper
                    style={{
                      backgroundColor: theme?.palette?.primary?.subSecondary,
                      color: theme?.palette?.primary?.placeholderColor, // Set text color
                    }}
                  >
                    {children}
                  </Paper>
                )}
                renderOption={(props, option) => (
                  <li
                    {...props}
                    key={option}
                    style={{ color: theme?.palette?.primary?.placeholderColor }}
                  >
                    {option}
                  </li>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item sm={3}>
            <FormControl fullWidth>
              <TextField
                variant="outlined"
                size="small"
                label="Max Number of Tokens"
                name="maxTokens"
                value={formik.values.maxTokens}
                // value={1000}
                onChange={formik.handleChange} // Ensure onChange is from formik
                sx={{
                  color: theme?.palette?.primary?.placeholderColor,
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "5px",
                    width: "100%",

                    backgroundColor:
                      formik.values.category === "medical"
                        ? theme?.palette?.primary?.subSecondary
                        : "",

                    color: theme?.palette?.primary?.placeholderColor, // Set text color
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: theme?.palette?.secondary?.inputColor,
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: theme?.palette?.secondary?.hoverColor,
                    },
                    "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
                      borderColor: theme?.palette?.secondary?.inputColor,
                    },
                    "&.Mui-disabled .MuiInputBase-input": {
                      color: `${theme?.palette?.primary?.main} !important`,
                      "-webkit-text-fill-color": `${theme?.palette?.primary?.main} !important`,
                    },
                  },
                  "& .MuiSvgIcon-root": {
                    color: theme?.palette?.primary?.placeholderColor,
                  },
                  "&.Mui-focused": {
                    color: theme?.palette?.primary?.placeholderColor, // Change label color when focused
                  },
                  "&.Mui-disabled": {
                    color: theme?.palette?.primary?.placeholderColor, // Text color for the disabled state
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: theme?.palette?.primary?.placeholderColor,
                  },
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

export default QuerieDropdwon;
